import Screen from '../components/Screen'
import { PageProps, Link } from 'gatsby'
import { RouteName } from '../utils/routes'

const Sdh = (props: PageProps) => {
  return (
    <Screen {...props}>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full">
          <h2 className="h2-medium divider pb-4 mb-4 mt-0 text-center">
            Stránka nebyla nalezena
          </h2>
          <p className="p1 text-center">
            Požadovaná stránka nebyla bohužel nalezena. Můžete pokračovat na
            <Link to={RouteName.HOME}> úvodní stránku</Link>.
          </p>
        </div>
      </div>
    </Screen>
  )
}

export default Sdh
